<template>
  <div>
    <h4 class="blue-text font-weight-bold">プロフィール編集</h4>
    <form v-on:submit="updateUserProfile">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-6">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 grey-text">名前 (姓)</p>
                <input
                  id="first-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="lastName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（姓）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 grey-text">名前 (名)</p>
                <input
                  id="last-name"
                  class="form-control shadow-1"
                  type="text"
                  minlength="1"
                  maxlength="100"
                  v-model="firstName"
                  v-on:keyup="formMessage = null"
                  placeholder="名前（名）を入力してください"
                  required
                />
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 grey-text">生年月日</p>
                <v-date-picker
                  v-model="birthDate"
                  :masks="{ L: 'YYYY-MM-DD' }"
                  locale="ja"
                  mode="date"
                  :max-date="new Date()"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <div class="date-picker-wrap">
                      <input
                        :value="inputValue"
                        v-on:click="togglePopover"
                        class="form-control datepicker-input shadow-1"
                        placeholder="YYYY-MM-DD"
                        required
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6">
              <div class="mt-4">
                <p class="m-0 grey-text">性別</p>
                <b-form-select
                  class="shadow-1"
                  v-model="gender"
                  :options="getGender()"
                  required
                ></b-form-select>
              </div>
            </div>
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 grey-text">
                  <span>都道府県</span>
                  <b-spinner small v-if="prefectures.loader"></b-spinner>
                </p>
                <b-form-select
                  class="shadow-1"
                  v-model="prefectures.value"
                  :options="prefectures.options"
                  required
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <b-alert
            class="m-0 mt-3 light-grey-outline grey-text"
            variant="warning"
            show
            v-if="formMessage !== null"
          >
            {{ formMessage }}
          </b-alert>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mt-5">
          <router-link
            :to="{
              name: 'adminRoute-dashboard',
            }"
          >
            <button type="button" class="btn btn-ds grey white-text">キャンセル</button>
          </router-link>
        </div>
        <div class="col-12 col-md-12 col-lg-12 col-xl-3 mt-5">
          <button
            class="btn btn-ds dark-blue white-text"
            type="submit"
            v-waves.light
          >
            更新
          </button>
        </div>
      </div>
    </form>

    <b-modal
      id="user-profile-update-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('user-profile-update-success-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          プロフィールが更新されました
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('user-profile-update-success-modal')"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { globalService_GetAllPrefectures } from '../../services/global';
import { adm_userService_UpdateUserProfile } from '../../services/admin/users';

// Import Moment JS
import moment from 'moment';

// Import Vue Calendar
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

//import common
import { getGender } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Profile | User Info',
    };
  },

  components: {
    'v-date-picker': DatePicker,
  },

  data() {
    return {
      firstName: null,
      lastName: null,
      birthDate: null,
      gender: null,
      prefectures: {
        value: null,
        options: [],
        loader: true,
      },
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  mounted() {
    this.setUserProfile();
    this.retievePrefectures();
  },

  methods: {
    getGender,
    setUserProfile() {
      if (this.currentUser.user_profile) {
        this.firstName = this.currentUser.user_profile.first_name;
        this.lastName = this.currentUser.user_profile.last_name;
        this.birthDate = this.currentUser.user_profile.birth_date;
        this.gender = this.currentUser.user_profile.gender;
        this.prefectures.value = this.currentUser.user_profile.prefectures;
      }
    },

    retievePrefectures() {
      let _this = this;

      _this.prefectures.loader = true;

      globalService_GetAllPrefectures()
        .then((response) => {
          let data = response.data;

          let prefectures = [];
          prefectures.push({
            value: null,
            text: '都道府県を選択してください',
            disabled: true,
          });

          data.forEach((el) => {
            prefectures.push({
              value: el.id,
              text: el.name,
            });
          });

          _this.prefectures.options = prefectures;

          _this.prefectures.loader = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    updateUserProfile(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      if (_this.firstName !== null) {
        if (_this.lastName !== null) {
          if (_this.birthDate !== null) {
            if (_this.gender !== null) {
              if (_this.prefectures.value !== null) {
                adm_userService_UpdateUserProfile({
                  id: _this.currentUser.id,
                  first_name: _this.firstName,
                  middle_name: null,
                  last_name: _this.lastName,
                  birth_date: _this.formatDate(_this.birthDate),
                  gender: _this.gender,
                  prefectures: _this.prefectures.value,
                  token: accessToken,
                })
                  .then(() => {
                    setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                      _this.$bvModal.show('user-profile-update-success-modal');
                    }, 500);
                  })
                  .catch((error) => {
                    console.log(error);

                    _this.formMessage =
                      'プロフィールの更新中にエラーが起こりました。再度お試しください。';

                    setTimeout(() => {
                      _this.$bvModal.hide('modal-loader');
                    }, 500);
                  });
              } else {
                _this.formMessage = 'Prefectures is required.';

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              }
            } else {
              _this.formMessage = 'Gender is required.';

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            }
          } else {
            _this.formMessage = 'Birthdate is required.';

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          }
        } else {
          _this.formMessage = 'last name is required.';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        }
      } else {
        _this.formMessage = 'First name is required.';

        setTimeout(() => {
          _this.$bvModal.hide('modal-loader');
        }, 500);
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom style */
</style>
